<template>
  <div class="exhibitionHall">
    <TopNavBar />
    <Carousel :carousels="carousels" />
    <div class="product_box">
      <Title title="产品矩阵" />
      <div class="products">
        <div
          v-for="(item, index) in products"
          :key="index"
          class="item"
          :style="{ backgroundColor: index % 2 === 1 ? '#f3f7fa' : '#fff' }"
          :class="item.direction"
        >
          <img :src="item.imgUrl" alt="" />
          <div>
            <h3>{{ item.title }}</h3>
            <p>{{ item.content }}</p>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
export default {
  name: "ExhibitionHall",
  data() {
    return {
      products: [
        {
          title: "飞屏交互",
          content:
            "将多点触摸技术和无线甩屏技术相结合，实现多媒体大屏演播点评以及甩屏互动的效果",
          direction: "left",
          imgUrl: require("@/assets/images/product/exhibitionHall/img0.svg"),
        },
        {
          title: "体感交互",
          content:
            "通过将多点触摸、无限遥感、无线甩屏等技术相结合，实现多媒体大屏演播点评以及各种互动展示效果。该系统一改传统展陈方 式，使展陈形式和主讲人(操作者)操作变得更加轻松、方便、灵活。",
          direction: "right",
          imgUrl: require("@/assets/images/product/exhibitionHall/img1.svg"),
        },
        {
          title: "智能语音",
          content:
            "智能语音交互是基于语音识别、语音合成、自然语言理解等技术，在多种实际应用场景下，赋予产品“能听、会说、懂你”式的智能人机交互功能。",
          direction: "left",
          imgUrl: require("@/assets/images/product/exhibitionHall/img2.svg"),
        },
        {
          title: "数字沙盘",
          content:
            "数字沙盘是对传统沙盘的一种改革性的创新，它在传统沙盘模型的基础上加入了以动态投影为主的各种多媒体声光手段，打破了人们对沙盘单调的印象。多媒体互动科技让整个沙盘都“动”了起来，使得整个沙盘展示的效果更加的炫目。",
          direction: "right",
          imgUrl: require("@/assets/images/product/exhibitionHall/img3.svg"),
        },
        {
          title: "艺术魔墙",
          content:
            "大型触摸拼接屏互动魔墙，通过拼接屏触摸屏互动大屏多媒体软件系统设置做成多个导航栏，可实现图片查看(放大、缩小、拖动)、 及图片预览，视频预览，内嵌 flash 内容，三维、3D 展示等等信息。",
          direction: "right",
          imgUrl: require("@/assets/images/product/exhibitionHall/img4.svg"),
        },
        {
          title: "投影互动融合",
          content:
            "投影融合技术就是将一组投影机投射出的画面进行边缘重叠，并通过融合技术显示出一个没有缝隙更加明亮、超大、高分辨率的整幅画面，画面的效果就象是一台投影机投射的画面。当二台或多台投影机组合投射一幅画面时，会有一部分影象重叠，边缘融合的主要功能就是把二台投影机重叠部分的灯光亮度逐渐调低，使整幅画面的亮度一致。",
          direction: "left",
          imgUrl: require("@/assets/images/product/exhibitionHall/img5.svg"),
        },
        {
          title: "智能中控",
          content:
            "随着多媒体互动科技的广泛运用，很多场所都是数字化的，里面大多是包含了声光电等手段来渲染现场的氛围。因此控制程序变的十分繁琐与复杂，于是便有了定制开发的中控系统。",
          direction: "right",
          imgUrl: require("@/assets/images/product/exhibitionHall/img6.svg"),
        },
        {
          title: "滑轨屏幕",
          content:
            "滑轨屏幕系统利用轨道的运动结合液晶屏幕(拼接屏幕、透明屏幕等显示端)实现对静态目标的自动连续透视或时间节点视频画面的 调用和播放功能，是自动控制和虚拟现实相结合的高科技自动交互技术。",
          direction: "left",
          imgUrl: require("@/assets/images/product/exhibitionHall/img7.svg"),
        },
        {
          title: "电子留言",
          content:
            "电子留言是一个集成了先进的手写数字技术，影像识别技术和多媒体软件技术于一体的高科技产品，来访者可以通过压感笔或者直接用手指在液晶屏上进行自由的板书，发表参观感言、提写宝贵意见、留下珍贵纪念。",
          direction: "left",
          imgUrl: require("@/assets/images/product/exhibitionHall/img8.svg"),
        },
      ],
      carousels: [
        {
          imgUrl: require("@/assets/images/carousels/10.png"),
        },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.exhibitionHall {
  .product_box {
    margin: 85px 0 80px;
    .products {
      margin-top: 70px;
      .item {
        padding: 80px 385px;
        display: flex;
        justify-content: space-between;
        > img {
          width: 300px;
          height: 171px;
        }
        > div {
          width: 100%;
          h3 {
            font-family: "AlibabaPuHuiTi-Regular";
            font-weight: 400;
            font-size: 25px;
            color: #333333;
            line-height: 33px;
          }
          p {
            font-family: "AlibabaPuHuiTi-Light";
            font-weight: 200;
            font-size: 20px;
            line-height: 40px;
            margin-top: 15px;
            letter-spacing: 2px;
          }
        }
      }
      .left {
        flex-direction: row;
        > img {
          margin-right: 40px;
        }
        > div {
          text-align: left;
        }
      }
      .right {
        flex-direction: row-reverse;
        > img {
          margin-left: 40px;
        }
        > div {
          text-align: right;
        }
      }
      .item:hover {
        > img {
          width: 400px;
          height: 228px;
        }
        > div {
          padding-top: 28.5px;
          h3,
          p {
            color: #006eff;
          }
          h3 {
            font-size: 30px;
          }
          p {
            font-size: 25px;
          }
        }
      }
      .left:hover {
        padding: 51.5px 385px 51.5px 285px;
      }
      .right:hover {
        padding: 51.5px 285px 51.5px 385px;
      }
    }
  }
}
</style>
